<template>
	<div class="h-full flex flex-col">
		<TopBar :showHome="true" :showChat="true"/>
		<ProgressBar :current="5"/>
		<AppTitle title="Summary"/>
		<div class="pt-4 text-gray-400 space-y-4 overflow-y-scroll flex-grow" v-if="table">

			<div v-if="isHost">
				<div class="flex items-center">
					<p class="flex-1 text-gray-600 font-bold text-lg">
						Table Name
					</p>
					<button @click="editingName=true" v-show="!editingName">
						<font-awesome-icon icon="fa-solid fa-pencil" class="text-red-500"/>
					</button>
					<button @click="editingName=false" v-show="editingName">
						<font-awesome-icon icon="fa-solid fa-close" class="text-gray-500"/>
					</button>
				</div>
				<div class="text-gray-600 mt-2" v-show="!editingName">
					<span class="text-red-500">{{ table.name }}</span> ({{ table.code.toUpperCase() }})
				</div>
				<div class="text-gray-600 mt-2" v-show="editingName">
					<div class="relative">
						<div class="absolute inset-y-0 right-0 pl-3 flex items-center">
							<button class="text-red-500 text-sm font-semibold px-2 py-3" @click="generateTableName">
								<font-awesome-icon icon="fa-solid fa-rotate" class="text-gray-500"/>
							</button>
							<button class="text-red-500 text-sm font-semibold focus:bg-red-100 pr-4 py-3 pl-2 rounded-r-xl" @click="saveName">Save</button>
						</div>
						<input type="text" class="block w-full pl-4 pr-16 py-4 rounded-xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" v-model="tableName" placeholder="Table Name">
					</div>
				</div>
			</div>

			<div>
				<div class="flex items-center">
					<p class="flex-1 text-gray-600 font-bold text-lg">
						Attendees
					</p>
					<button @click="updateStep(1)" v-show="isHost">
						<font-awesome-icon icon="fa-solid fa-pencil" class="text-red-500"/>
					</button>
				</div>
				<div class="flex mt-2 space-x-2">
					<user-profile-picture 
						:user="currentUser"
						:size="10"
					/>
					<user-profile-picture 
						:user="user"
						:size="10"
						v-for="user in table.users"
						:key="user.uuid"
					/>
				</div>
			</div>

			<div>
				<div class="flex items-center">
					<p class="flex-1 text-gray-600 font-bold text-lg">
						Food
					</p>
					<button @click="updateStep(2)">
						<font-awesome-icon icon="fa-solid fa-pencil" class="text-red-500"/>
					</button>
				</div>
				<div class="block w-full pl-6 py-4 pr-4 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 bg-white mt-2" v-if="!cuisines || cuisines.length===0">
					No preference
				</div>
				<div class="grid grid-cols-2 gap-2 mt-2 text-gray-600" v-else>
					<div class="bg-white w-full rounded-xl border boder-gray-100 py-3 px-4 space-y-1" v-show="liked.length">
						<p v-for="cuisine in liked" :key="cuisine.name">
							<font-awesome-icon icon="fa-solid fa-check" class="text-green-400 mr-1"/> {{ cuisine.label }}
						</p>
					</div>
					<div class="bg-white w-full rounded-xl border boder-gray-100 py-3 px-4 space-y-1" v-show="disliked.length">
						<p v-for="cuisine in disliked" :key="cuisine.name">
							<font-awesome-icon icon="fa-solid fa-xmark" class="text-red-500 mr-1"/> {{ cuisine.label }}
						</p>
					</div>
				</div>
			</div>

			<div>
				<div class="flex items-center">
					<p class="flex-1 text-gray-600 font-bold text-lg">
						Location
					</p>
					<button @click="updateStep(3)">
						<font-awesome-icon icon="fa-solid fa-pencil" class="text-red-500"/>
					</button>
				</div>
				<div class="mt-2 relative">
					<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
						<font-awesome-icon icon="fa-solid fa-location-dot" class="pl-2 text-red-500"/>
					</div>
					<p class="block w-full pl-10 py-4 pr-4 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 bg-white" v-text="finalAddress"></p>
				</div>
			</div>

			<div>
				<div class="flex items-center">
					<p class="flex-1 text-gray-600 font-bold text-lg">
						Price
					</p>
					<button @click="updateStep(4)">
						<font-awesome-icon icon="fa-solid fa-pencil" class="text-red-500"/>
					</button>
				</div>
				<div class="mt-2 relative">
					<p class="block w-full pl-6 py-4 pr-4 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 bg-white">{{ finalPricing }}</p>
				</div>
			</div>
		</div>
		<div class="relative w-full pt-10 pb-6 bg-gray-50">
			<div>
				<button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="submit">
					Confirm
				</button>
				<button class="text-gray-500 py-2 rounded-xl w-full font-gothic text-lg mt-4" @click="previous">
					Back
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import ProgressBar from '@/components/ProgressBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	import UserProfilePicture from '@/components/UserProfilePicture';
	export default {
		components: {
			TopBar,
			AppTitle,
			ProgressBar,
			UserProfilePicture,
		},
		data(){
			return {
				editingName: false,
				tableName: ''
			}
		},
		watch: {
			editingName(newVar, oldVar){
				if (newVar && !oldVar){
					this.tableName = this.table.name;
				}
			}
		},
		computed: {
			isHost(){
				return this.table.user_id === this.$store.state.auth.user.id;
			},
			currentUser(){
				return this.$store.state.auth.user;
			},
			finalPricing(){
				if (!this.answers.pricing || this.answers.pricing === 0){
					return "No preference";
				}
				return '$'.repeat(this.answers.pricing);
			},
			finalAddress(){
				let address = this.answers.address.name;
				if (this.answers.distance >= 20){
					return address;
				} else {
					return this.answers.distance + " mi around " + address;
				}
			},
			cuisines(){
				return this.answers.cuisines;
			},
			liked(){
				return (this.cuisines) 
					? this.cuisines.filter( x => x.score > 0 )
					: [];
			},
			disliked(){
				return (this.cuisines) 
					? this.cuisines.filter( x => x.score < 0 )
					: [];
			},
			where(){
				return this.$store.state.userLocation ? "my location" : this.answers.address.formatted_address;
			},
			table() {
				return this.$store.state.table.table;
			},
			answers() {
				return this.table.answers;
			},
		},
		methods: {
			updateStep(value){
				this.$store.commit('table/SET_STEP', value);
			},
			generateTableName(){
				this.$store.dispatch('table/generateTableName').then( r => {
					this.tableName = r.data;
				});
			},
			saveName(){
				this.$store.dispatch('table/saveTableName', {
					table_uuid: this.table.uuid,
					name: this.tableName
				}).then( () => {
					this.$store.dispatch('table/getTable', {
						uuid: this.table.uuid
					}).then( () => {
						this.editingName = false;
					});
				});
			},
			submit(){
				this.$store.dispatch('table/submitAnswers', {
					step: 6
				});
			},
			previous(){
				this.$store.dispatch('table/saveAnswers', {
					step: 4
				});
			},
		}
	}
</script>